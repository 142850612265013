import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = ({ location }) => (
  <Layout location={location} lang={'ca'}>
    <div id="main">
      <h1>NO S'HA TROBAT</h1>
      <p>La ruta que has obert no existeix...</p>
    </div>
  </Layout>
)

export default NotFoundPage
